import { withStyles } from '@material-ui/core';

function getColor(color, theme, brigtness = 'main') {
  if (color && theme.palette[color] && theme.palette[color][brigtness]) {
    return theme.palette[color][brigtness];
  }
}

function createStyled(styles, options) {
  const Styled = (props) => {
    const { children, ...other } = props;
    return children(other);
  };

  return withStyles(styles, options)(Styled);
}

export { getColor, createStyled };
