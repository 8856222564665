import PropTypes from 'prop-types';
import React from 'react';
import {
  AddressCardIcon,
  CompareArrowsIcon,
  UserSecretIcon,
} from '../../Icons';

function DynamicDashboardIcon({ icon }) {
  return (() => {
    switch (icon) {
      case 'far fa-address-card':
        return <AddressCardIcon />;
      case 'compare_arrows':
        return <CompareArrowsIcon />;
      case 'fas fa-user-secret':
        return <UserSecretIcon />;
      default:
        return null;
    }
  })();
}

DynamicDashboardIcon.propTypes = {
  icon: PropTypes.string,
};

export default DynamicDashboardIcon;
