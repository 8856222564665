import { getOktaAccessToken } from '@Utils/common';
import idToken from '@Utils/iap';
import services from '@Utils/services.json';

const fetcher = async (url, init) => {
  const oktaAccessToken = await getOktaAccessToken();
  const projectId = Object.keys(services).find((key) => url.includes(key));
  const oidcToken = await idToken(projectId);
  const response = await fetch(url, {
    ...init,
    headers: {
      Authorization: `Bearer ${oidcToken || oktaAccessToken}`,
      ...init?.header,
    },
  });

  if (response.status === 200) return response.json();
  if (response.status === 400) {
    const message = await response.json();
    throw new Error(message || 'Bad Request');
  }
  if (response.status === 401) {
    const message = await response.json();
    throw new Error(message || 'Unauthorized');
  }
  if (response.status === 404) {
    const message = await response.json();
    throw new Error(message || 'Not Found');
  }

  return response.json();
};

export default fetcher;
