import { makeStyles } from '@material-ui/core/styles';
import iAmError from '../../images/i_am_error.png';

export default makeStyles((theme) => ({
  container: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${iAmError})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));
