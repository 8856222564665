import jsonwebtoken from 'jsonwebtoken';
import services from './services.json';

const jwtPayload = (service) => ({
  iss: service.client_email,
  aud: 'https://www.googleapis.com/oauth2/v4/token',
  target_audience: service.client_id,
  iat: Math.floor(new Date().getTime() / 1000),
  exp: Math.floor(new Date().getTime() / 1000) + 3600,
});

const jwt = (service) => {
  const payload = jwtPayload(service);
  return jsonwebtoken.sign({ ...payload }, service.private_key, {
    algorithm: 'RS256',
  });
};

const idToken = async (projectId) => {
  if (!projectId) return;
  const service = services[projectId];
  const token = jwt(service);
  const response = await fetch('https://www.googleapis.com/oauth2/v4/token', {
    method: 'POST',
    body: `grant_type=urn:ietf:params:oauth:grant-type:jwt-bearer&assertion=${token}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
  const data = await response.json();
  return data.id_token;
};

const queryString = (params) => {
  let qs = '';
  if (typeof params === 'object') {
    if (Object.keys(params).length) {
      qs += `?${Object.keys(params)
        .map((param) => `${param}=${params[param]}`)
        .join('&')}`;
    }
  } else {
    throw new Error('"params" must be an object');
  }
  return qs;
};

export default idToken;
