import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  hidden: {
    opacity: 0,
  },
  list: {
    borderTop: "1px",
    borderTopStyle: "solid",
    borderTopColor: "rgba(0, 0, 0, 0.12)",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  title: {
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(2),
    textTransform: "uppercase",
    transition: theme.transitions.create(["opacity"]),
  },
}));
