import React, { useContext, useReducer } from 'react';

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

const initialState = {};

function userReducer(state, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
        isAuthenticated: action.isAuthenticated,
        accessToken: action.accessToken,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider');
  }
  return context;
}

function useUserDispatch() {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

const useSetUser = () => {
  const dispatch = useUserDispatch();
  return ({ user, isAuthenticated, accessToken }) => {
    dispatch({ type: 'SET_USER', user, isAuthenticated, accessToken });
  };
};

const useIsAdminUser = () => {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useIsAdminUser must be used within a UserProvider');
  }
  return context.user?.customerId === '0';
};

export {
  UserStateContext,
  UserProvider,
  useUserState,
  useUserDispatch,
  useSetUser,
  useIsAdminUser,
  loginUser,
  signOut,
};

// ###########################################################

function loginUser(dispatch, login, password, history, setIsLoading, setError) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    setTimeout(() => {
      localStorage.setItem('id_token', 1);
      setError(null);
      setIsLoading(false);
      dispatch({ type: 'LOGIN_SUCCESS' });

      history.push('/app/dashboard');
    }, 2000);
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
    setError(true);
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem('id_token');
  dispatch({ type: 'SIGN_OUT_SUCCESS' });
  history.push('/login');
}
