import tinycolor from "tinycolor2";

const primary = "#0277BD";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#9013FE";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#000000",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
    f5green: {
      main: "#00AA74",
      medium: "#67CAAA",
      light: "#CCEEE3",
      disabled: "#99DDC7",
    },
    f5red: {
      main: "#E34C5C",
      medium: "#EE7A86",
      disabled: "#F4B7BE",
    },
    f5blue: {
      main: "#0277BD",
      dark: "#0C5C8D",
      disabled: "#9AC9E5",
    },
    f5orange: {
      main: "#EA6712",
      RUST: "#bd7421",
      TANGERINE: "#f29a36",
      PUMPKIN: "#ffb460",
      disabled: "#ffb460",
    },
    f5yellow: {
      main: "#EBB447",
      mainOpaque: "#EBB4474C",
    },
    tooltipBackground: {
      main: "#424242",
    },
    white: {
      main: "#FFFFFF",
    },
    black: {
      main: "#000000",
    },
  },
  fonts: {
    mainFont: `"Montserrat", sans-serif`,
    titleFont: `"Oswald", sans-serif`,
  },
  fontWeights: {
    mediumBold: 600,
  },
  lineHeights: {
    none: 0,
    subtitle2: 0.9,
  },
  customShadows: {
    widget: "0px 4px 8px rgba(0, 0, 0, 0.15), 0px 2px 4px rgba(0, 0, 0, 0.1)",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A33",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid rgba(224, 224, 224, .5)",
      },
      head: {
        fontSize: "0.95rem",
      },
      body: {
        fontSize: "0.95rem",
      },
    },
    MUIDataTableFilter: {
      root: {
        backgroundColor: "#FFF",
      },
    },
    MUIDataTableToolbarSelect: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        boxShadow: "none",
        borderRadius: 0,
      },
    },
    MuiTreeItem: {
      root: {
        "&:focus > $content": {
          backgroundColor: "",
        },
      },
    },
    MuiIcon: {
      root: {
        width: "auto",
      },
    },
    MuiTableRow: {
      root: {
        "&:last-child td": {
          borderBottom: 0,
        },
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
      select: {
        "&:focus": {
          backgroundColor: "$labelcolor",
        },
      },
    },
    MuiAutocomplete: {
      paper: {
        boxShadow:
          "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
      },
      option: {
        '&[data-focus="true"][aria-selected="true"]': {
          backgroundColor: "#F3F5FF !important",
        },
        '&[aria-selected="true"]': {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    RechartsCartesianGridHorizontal: {
      "&first-child": {
        strokeOpacity: 0,
      },
    },
    MuiDialog: {
      paper: {
        minWidth: 300,
      },
    },
  },
};

export default defaultTheme;
