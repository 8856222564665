import React from 'react';
import SvgIcon from '../SvgIcon';

function AddIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    </SvgIcon>
  );
}

export default AddIcon;
