import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  dotBase: {
    backgroundColor: theme.palette.text.disabled,
    borderRadius: "50%",
    height: 8,
    transition: theme.transitions.create("background-color"),
    width: 8,
  },
  dotSmall: {
    height: 5,
    width: 5,
  },
  dotLarge: {
    height: 11,
    width: 11,
  },
}));
