import React from "react";

function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 81 81"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g fill="#02723b" stroke="none">
        <use xlinkHref="#C" />
        <use xlinkHref="#C" y="-9.498" />
        <use xlinkHref="#C" y="9.71" />
        <use xlinkHref="#C" y="19.209" />
        <path d="M57.684 55.726h12.842v6.332H57.684zM77.895 8.654H46.947V0L0 8.232v63.747L46.947 80v-9.71h30.947c1.263 0 2.105-1.055 2.105-2.111V10.765c0-1.055-.842-2.111-2.105-2.111zM26.526 54.248l-4.421-11.609h-.211c0 .211-4.421 10.976-4.421 10.976l-5.895-.422 6.947-13.72-6.316-13.72 5.895-.422 4 10.765h.211c0-.211 4.421-11.187 4.421-11.187l6.105-.422-7.368 14.987 7.579 14.987-6.526-.211zm50.737 13.72H46.947v-5.91h7.368v-6.333h-7.368v-3.166h7.368v-6.332h-7.368v-3.166h7.368v-6.332h-7.368v-3.166h7.368V27.23h-7.368v-3.166h7.368v-6.332h-7.368V11.4h30.316v56.57z" />
      </g>
      <defs>
        <path id="C" d="M57.684 26.807h12.842v6.333H57.684z" />
      </defs>
    </svg>
  );
}

export default ExcelIcon;
