import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(
  (theme) => ({
    root: {
      display: "grid",
      gridTemplateAreas: "'header header' 'sidebar main'",
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "auto 1fr",
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      overflow: "hidden",
    },
    main: {
      gridArea: "main",
      padding: theme.spacing(6),
      overflow: "overlay",
    },
  }),
  { name: "Layout" }
);
