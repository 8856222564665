import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.primary.main,
      '& $linkIcon div': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  linkActive: {
    borderRight: '4px',
    borderRightColor: `${theme.palette.f5red.main} !important`,
    borderRightStyle: 'solid',
    color: `${theme.palette.f5red.main} !important`,
  },
  linkIcon: {
    color: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    minWidth: 0,
    transition: theme.transitions.create('color'),
    width: 24,
  },
  linkIconActive: {
    color: theme.palette.f5red.main,
  },
  linkNested: {
    paddingLeft: theme.spacing(4),
  },
  linkText: {
    color: 'inherit',
    fontSize: 16,
    padding: 0,
    transition: theme.transitions.create(['opacity', 'color']),
  },
  linkTextActive: {
    color: theme.palette.f5red.main,
  },
  linkTextHidden: {
    opacity: 0,
  },
}));
