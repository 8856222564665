// react
import { PersonIcon } from '@Components/Icons';
// components
import { Typography } from '@Components/Wrappers';
import { useUserState } from '@Context/UserContext';
// images
import { ReactComponent as ShapeLogo } from '@Images/shape_logo_white.svg';
// mui
import { AppBar, Button, IconButton, Menu, Toolbar } from '@material-ui/core';
// auth
import { useOktaAuth } from '@okta/okta-react';
import React, { useState } from 'react';
// styles
import useStyles from './styles';

function AppHeader() {
  const { oktaAuth } = useOktaAuth();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const { user, isAuthenticated } = useUserState();

  const classes = useStyles();

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar className={classes.toolbar}>
        <ShapeLogo data-testid="shape-logo" className={classes.shapeLogo} />
        <div className={classes.grow}>Part of F5</div>
        {user && (
          <>
            <IconButton
              className={classes.iconButton}
              color="inherit"
              onClick={(event) => setAccountMenuAnchorEl(event.currentTarget)}
            >
              <PersonIcon />
            </IconButton>
            <Menu
              anchorEl={accountMenuAnchorEl}
              classes={{ paper: classes.accountMenu }}
              className={classes.menu}
              disableAutoFocusItem
              onClose={() => setAccountMenuAnchorEl(null)}
              open={Boolean(accountMenuAnchorEl)}
            >
              <div className={classes.accountMenuItem}>
                <Typography variant="h4" weight="medium">
                  {user.name}
                </Typography>
                <Typography>{user.customerName}</Typography>
                {!isAuthenticated && (
                  <small>
                    (<i>Unauthenticated</i>)
                  </small>
                )}
              </div>
              {isAuthenticated ? (
                <div className={classes.accountMenuItem}>
                  <Typography
                    className={classes.accountMenuLink}
                    color="primary"
                    onClick={() => oktaAuth.signOut()}
                  >
                    Log Out
                  </Typography>
                </div>
              ) : (
                <div className={classes.accountMenuItem}>
                  <Button
                    color="primary"
                    onClick={() => (window.location = '/login')}
                    variant="contained"
                  >
                    Login
                  </Button>
                </div>
              )}
            </Menu>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppHeader;
