import {
  amber,
  blue,
  // grey,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import { OktaAuth } from '@okta/okta-auth-js';
import { scaleOrdinal } from 'd3-scale';
import jsonwebtoken from 'jsonwebtoken';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import maxBy from 'lodash/maxBy';
import meanBy from 'lodash/meanBy';
import minBy from 'lodash/minBy';
import sample from 'lodash/sample';
import sumBy from 'lodash/sumBy';

function getColors(nested = true, scheme = false) {
  const hues = [
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    // grey,
    blueGrey,
  ];

  let hexes = [];

  if (scheme) {
    [500, 300, 100].forEach((shade) => {
      hues.forEach((hue) => {
        hexes.push(hue[shade]);
      });
    });
  } else if (nested) {
    hexes = hues.map((color) => [
      color[100],
      color[300],
      color[500],
      color[700],
      color[900],
    ]);
  } else {
    hexes = hues.flatMap((color) => [
      color[100],
      color[300],
      color[500],
      color[700],
      color[900],
    ]);
  }

  return hexes;
}

function getRandomColor(exclude = []) {
  const colors = getColors(false).filter((color) => !exclude.includes(color));
  return sample(colors);
}

const CHART_COLORS = {
  F5RED: '#e4002b',
  NGINXGREEN: '#009639',
  LAGOON: '#1d9cd3',
  LILAC: '#8b5ba5',
  TULIP: '#ee4db5',
  FERN: '#62c026',
  BUMBLEBEE: '#fff200',
  CORAL: '#f3704b',
};

// Colors from https://www.f5.com/pdf/f5/F5-Creative-Guidelines.pdf
const getChartColorScale = ({ domainData }) =>
  scaleOrdinal()
    .domain(domainData)
    .range([
      CHART_COLORS.F5RED,
      CHART_COLORS.NGINXGREEN,
      CHART_COLORS.LAGOON,
      CHART_COLORS.LILAC,
      CHART_COLORS.TULIP,
      CHART_COLORS.FERN,
      CHART_COLORS.BUMBLEBEE,
      CHART_COLORS.CORAL,
    ]);

function getTextWidth(text) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font =
    '1rem Roboto, Montserrat, Oswald, Helvetica, Arial, sans-serif';
  const { width } = context.measureText(text);
  return Math.ceil(width + 2);
}

function getJWT(apiId, apiKey) {
  const payload = {
    iat: Math.floor(Date.now() / 1000),
    exp: Math.floor(Date.now() / 1000) + 60 * 60,
  };
  const options = {
    keyid: apiId,
    algorithm: 'HS256',
    subject: 'Shape Security',
  };

  return jsonwebtoken.sign(payload, apiKey, options);
}

async function sha256(str) {
  const buf = await crypto.subtle.digest(
    'SHA-256',
    new TextEncoder('utf-8').encode(str)
  );
  return Array.prototype.map
    .call(new Uint8Array(buf), (x) => `00${x.toString(16)}`.slice(-2))
    .join('');
}

function processTextFieldInput(textFieldInput) {
  let inputStr = textFieldInput;
  inputStr = inputStr.replace(/\n/g, ','); // replace newlines with commas
  inputStr = inputStr.replace(/\r/g, ','); // replace carriage returns with commas
  let inputArr = inputStr.split(','); // convert to array
  inputArr = [...new Set(inputArr)]; // remove duplicates
  inputArr = inputArr.filter((value) => value.length > 0); // remove empty values
  inputArr = inputArr.map((value) => value.toLowerCase()); // convert to lowercase
  return inputArr;
}

const uniqueId = () => {
  let num = 0;
  return (prefix = '') => {
    num += 1;
    return String(prefix) + num;
  };
};

const unpivot = ({
  data,
  sumByValue,
  column = 'series',
  groupByValue = 'date',
}) => {
  if (!sumByValue) return data;
  const distinctValues = [...new Set(data.map((row) => row[column]))];

  const dateGroupedData = groupBy(data, groupByValue);
  const mappedData = map(dateGroupedData, (obj, key) => {
    const row = { [groupByValue]: key };
    distinctValues.forEach((distinctValue) => {
      row[distinctValue] = sumBy(obj, (item) =>
        parseFloat(item[column] === distinctValue ? item[sumByValue] : 0)
      );
    });
    if (Object.prototype.hasOwnProperty.call(obj[0], 'date_label')) {
      row.date_label = obj[0].date_label;
    }
    return row;
  });

  return mappedData;
};

const toCamelCase = (sentenceCase) => {
  let out = '';
  sentenceCase.split(' ').forEach((el, idx) => {
    const add = el.toLowerCase();
    out += idx === 0 ? add : add[0].toUpperCase() + add.slice(1);
  });
  return out;
};

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getSeriesTotals = (
  data,
  valueField = 'value',
  seriesField = 'series'
) => {
  // Aggregate on date column
  const seriesFieldGroupedData = groupBy(data, seriesField);
  const mappedData = map(seriesFieldGroupedData, (value, key) => {
    const sum = sumBy(value, (row) => row[valueField]);
    const min = minBy(value, (row) => row[valueField]);
    const max = maxBy(value, (row) => row[valueField]);
    const avg = meanBy(value, (row) => row[valueField]);
    const ratio =
      Math.abs(sum) / sumBy(data, (row) => Math.abs(row[valueField]));
    return {
      name: key,
      sum,
      min: min[valueField],
      max: max[valueField],
      avg,
      ratio,
    };
  });
  const sortedMappedData = mappedData.sort((a, b) => (a.sum < b.sum ? 1 : -1));

  return sortedMappedData;
};

const runningTotal = (data, field, reverse = false) => {
  if (reverse === true) {
    data?.reverse();
  }
  let total = 0;
  data?.map((datum) => {
    const row = datum;
    total += row[field];
    row[field] = total;
    return row;
  });
  if (reverse === true) {
    data?.reverse();
  }
  return data;
};

const config = {
  clientId: '0oa2w43h1efSiEb2E4x6',
  issuer: 'https://ssan118551.okta.com/oauth2/default',
  pkce: true,
  redirectUri: `${window.location.origin}/implicit/callback`,
};

async function getOktaAccessToken() {
  const authClient = new OktaAuth(config);
  const { tokenManager } = authClient;
  const accessTokenObj = await tokenManager.get('accessToken');
  return accessTokenObj?.accessToken;
}

function filterNull(obj) {
  return Object.fromEntries(Object.entries(obj).filter((entry) => entry[1]));
}

export {
  capitalize,
  config,
  filterNull,
  getColors,
  getJWT,
  getOktaAccessToken,
  getRandomColor,
  getChartColorScale,
  getSeriesTotals,
  getTextWidth,
  processTextFieldInput,
  runningTotal,
  sha256,
  toCamelCase,
  uniqueId,
  unpivot,
};
