import { Box } from '@material-ui/core';
import React from 'react';
// styles
import useStyles from './styles';

export default function Error() {
  const classes = useStyles();

  return <Box className={classes.container} />;
}
