import { Button as ButtonBase } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import React from 'react';
import { createStyled, getColor } from './utils';

function Button({ children, color, ...props }) {
  const theme = useTheme();

  const Styled = createStyled({
    button: {
      backgroundColor: getColor(color, theme),
      boxShadow: theme.customShadows.widget,
      color: 'white',
      '&:hover': {
        backgroundColor: getColor(color, theme, 'light'),
        boxShadow: theme.customShadows.widgetWide,
      },
    },
  });

  return (
    <Styled>
      {({ classes }) => (
        <ButtonBase classes={{ root: classes.button }} {...props}>
          {children}
        </ButtonBase>
      )}
    </Styled>
  );
}

export default Button;
