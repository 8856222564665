// react
import React from "react";

// mui
import { useTheme } from "@material-ui/core/styles";

// npm
import cx from "classnames";

// styles
import useStyles from "./styles";

function Dot(props) {
  const { size, color } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div
      className={cx(classes.dotBase, {
        [classes.dotLarge]: size === "large",
        [classes.dotSmall]: size === "small",
      })}
      style={{
        backgroundColor:
          color && theme.palette[color] && theme.palette[color].main,
      }}
    />
  );
}

export default Dot;
