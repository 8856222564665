import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  accountMenu: {
    minWidth: 240,
  },
  accountMenuItem: {
    outline: "none",
    padding: theme.spacing(2),
  },
  accountMenuLink: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  appBar: {
    gridArea: "header",
    backgroundColor: "#1c1c1c",
    transition: theme.transitions.create(["margin"], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp,
    }),
    width: "100vw",
    zIndex: theme.zIndex.drawer + 1,
  },
  grow: {
    borderLeft: "1px",
    borderLeftColor: theme.palette.text.secondary,
    borderLeftStyle: "solid",
    color: theme.palette.text.secondary,
    flexGrow: 1,
    height: "24px",
    lineHeight: "24px",
    marginLeft: "16px",
    paddingLeft: "16px",
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: theme.spacing(0.5),
  },
  menu: {
    marginTop: theme.spacing(5),
  },
  shapeLogo: {
    width: "165px",
  },
  toolbar: {
    minHeight: "48px",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
