import React from "react";
import SvgIcon from "../SvgIcon";

function VPNIcon({ ...rest }) {
  return (
    <SvgIcon viewBox="0 0 111.82 122.88" {...rest}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.72,0c20.87,13.2,39.67,19.47,55.85,17.99c2.84,57.11-18.25,90.84-55.63,104.89 C19.84,109.72-1.5,77.42,0.08,17.11C19.07,18.1,37.69,14.01,55.72,0L55.72,0z M20.14,44.81h7.11l4.95,15.83l4.87-15.83h6.9 L35.81,66.8h-7.36L20.14,44.81L20.14,44.81z M45.67,44.81h11.29c2.46,0,4.3,0.58,5.52,1.76c1.22,1.17,1.84,2.84,1.84,4.99 c0,2.22-0.67,3.96-2,5.21c-1.34,1.25-3.37,1.87-6.11,1.87h-3.72v8.16h-6.82V44.81L45.67,44.81z M52.49,54.18h1.66 c1.31,0,2.23-0.23,2.76-0.68c0.53-0.46,0.79-1.03,0.79-1.75c0-0.69-0.23-1.28-0.69-1.76c-0.46-0.48-1.32-0.72-2.59-0.72h-1.94 V54.18L52.49,54.18z M67.41,44.81h6.35l8.28,12.17V44.81h6.41V66.8h-6.41L73.8,54.72V66.8h-6.39V44.81L67.41,44.81z M55.72,7.04 c18.47,11.69,35.13,17.22,49.44,15.93c2.51,50.55-16.18,80.41-49.26,92.87C23.97,104.19,5.06,75.62,6.46,22.23 c16.81,0.88,33.29-2.76,49.26-15.15V7.04L55.72,7.04z"
      />
    </SvgIcon>
  );
}

export default VPNIcon;
