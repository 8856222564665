import React from 'react';
import SvgIcon from '../SvgIcon';

function VMIcon({ ...rest }) {
  return (
    <SvgIcon viewBox="0 0 81 81" {...rest}>
      <path
        d="M50.566 45.075c.064 0 .107-.021.15-.043l10.705-6.141c.107-.042.172-.149.172-.277 0-.107-.065-.213-.172-.277l-10.769-6.162c-.108-.064-.215-.064-.322 0l-10.705 6.119a.31.31 0 0 0-.15.277c0 .128.043.234.15.277l10.77 6.183c.064.022.107.043.172.043zm-1.545 14.989c.043 0 .107 0 .15-.021a.34.34 0 0 0 .172-.299V47.399c0-.107-.064-.213-.172-.277l-10.727-6.141a.33.33 0 0 0-.343 0c-.086.043-.15.149-.15.277v12.346c0 .107.064.213.15.277l10.748 6.141c.043.042.107.042.172.042zm3.025 0c.064 0 .129 0 .172-.021l10.727-6.162c.107-.064.172-.17.172-.277v-12.26a.34.34 0 0 0-.172-.298.42.42 0 0 0-.322 0l-10.727 6.162c-.107.064-.172.17-.172.277v12.26c0 .128.064.234.172.277.043.042.107.042.15.042zm-4.505-36.119l-6.586-3.774c-.107-.064-.236-.064-.322 0l-6.608 3.774H22.14c-1.824 0-3.304 1.642-3.304 3.454v24.264h-4.591V16.631l51.874-.064v7.377H47.541zm10.963 44.371H76.31c1.823 0 3.69-1.621 3.69-3.433V27.399c0-1.812-1.866-3.454-3.69-3.454h-5.535v-8.529c0-1.834-1.867-3.454-3.69-3.454h-5.921V3.454C61.185 1.62 59.319 0 57.474 0H3.325C1.48 0 0 1.642 0 3.454v37.484c0 1.812 1.48 3.433 3.325 3.433h6.286V52.9c0 1.812 1.48 3.433 3.325 3.433h5.9v8.55c0 1.812 1.48 3.433 3.304 3.433h19.716c1.995 7.015-.687 8.017-12.443 8.017V80h39.989v-3.667c-11.757 0-12.915-1.002-10.898-8.017zm17.335-4.67H23.942V28.635l51.896-.085v35.096zM37.887 11.962l-6.543-3.753a.42.42 0 0 0-.322 0l-6.543 3.753H12.936c-1.845 0-3.325 1.62-3.325 3.454v24.286H4.634V4.67l51.896-.064v7.356H37.887z"
        fill="currentColor"
        stroke="none"
      />
    </SvgIcon>
  );
}

export default VMIcon;
