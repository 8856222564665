import React from 'react';
import SvgIcon from '../SvgIcon';

function ArrowRightIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M10 17l5-5-5-5v10z" />
      <path d="M0 24V0h24v24H0z" fill="none" />
    </SvgIcon>
  );
}

export default ArrowRightIcon;
