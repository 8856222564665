import callFetch from './api';

export async function getDashboards(enterpriseId, isActive) {
  return callFetch({
    url: `api/v1/dashboards/${
      (enterpriseId !== undefined ? `?enterprise_id=${enterpriseId}` : '') +
      (isActive !== undefined ? `&is_active=${isActive}` : '')
    }`,
  }).then((response) => response.json());
  // TODO: convert to return response object, not JSON, update usages
}

export async function getDashboard(dashboardId) {
  try {
    const response = await callFetch({
      url: `api/v1/dashboards/${dashboardId}`,
    });

    if (response.ok) {
      return response.json();
    }

    throw new Error(response.statusText);
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
    }
    throw error;
  }
}

export async function createDashboard(config) {
  try {
    const response = await callFetch({
      url: `api/v1/dashboards/`,
      init: {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ enterprise_id: 0, config: { ...config } }),
        method: 'POST',
      },
    });

    if (response.ok) {
      return response.json();
    }

    throw new Error(response.statusText);
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
    }
    throw error;
  }
  // TODO: convert to return response object, not JSON, update usages
}

export async function updateDashboard(dashboardId, config) {
  try {
    const response = await callFetch({
      url: `api/v1/dashboards/${dashboardId}`,
      init: {
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(config),
        method: 'PUT',
      },
    });

    if (response.ok) {
      return response.json();
    }

    throw new Error(response.statusText);
  } catch (error) {
    if (error.name !== 'AbortError') {
      console.error(error);
    }
    throw error;
  }
}

export async function deleteDashboard(dashboardId) {
  return callFetch({
    url: `api/v1/dashboards/${dashboardId}`,
    init: {
      method: 'DELETE',
    },
  });
}
