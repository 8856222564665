import React from 'react';
import SvgIcon from '../SvgIcon';

function ScatterPlotIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <g>
        <circle cx="7" cy="14" r="3" />
        <circle cx="11" cy="6" r="3" />
        <circle cx="16.6" cy="17.6" r="3" />
      </g>
    </SvgIcon>
  );
}

export default ScatterPlotIcon;
