import React from "react";

function BigQueryIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 81 81"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="none">
        <path
          d="M18.919 35.318v10.519a19.64 19.64 0 0 0 6.84 6.96V35.318z"
          fill="#aecbfa"
        />
        <path
          d="M31.558 25.639v30.118a30.48 30.48 0 0 0 4.44.24c1.332.083 2.668.083 4 0V25.639z"
          fill="#669df6"
        />
        <path
          d="M46.558 39.438v13.159a20 20 0 0 0 6.8-7.28v-5.88z"
          fill="#aecbfa"
        />
        <path
          d="M62.957 57.277l-5.68 5.68a1.68 1.68 0 0 0 0 2.4l14.159 14.159a1.68 1.68 0 0 0 2.36 0l5.72-5.72a1.68 1.68 0 0 0 0-2.36L65.357 57.277a1.68 1.68 0 0 0-2.4 0"
          fill="#4285f4"
        />
        <path
          d="M35.998 0C16.117 0 0 16.117 0 35.998s16.117 35.998 35.998 35.998 35.998-16.117 35.998-35.998S55.879 0 35.998 0m0 62.757C21.252 62.735 9.312 50.771 9.32 36.025S21.279 9.327 36.025 9.32s26.71 11.933 26.732 26.679a26.72 26.72 0 0 1-26.759 26.759"
          fill="#669df6"
        />
      </g>
    </svg>
  );
}

export default BigQueryIcon;
