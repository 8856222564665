import React from 'react';
import SvgIcon from '../SvgIcon';

function ShowChartIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M3.5 18.49l6-6.01 4 4L22 6.92l-1.41-1.41-7.09 7.97-4-4L2 16.99z" />
    </SvgIcon>
  );
}

export default ShowChartIcon;
