import React from 'react';
import SvgIcon from '../SvgIcon';

function ArrowDropDownCircleIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 13l-4-4h8l-4 4z" />
    </SvgIcon>
  );
}

export default ArrowDropDownCircleIcon;
