import React from "react";

function GoogleSheetsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 59 81"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g stroke="none">
        <path
          d="M36 0l22 22v53a4.99 4.99 0 0 1-5 5H5a4.99 4.99 0 0 1-5-5V5a4.99 4.99 0 0 1 5-5z"
          fill="#28b446"
        />
        <path d="M36 0l22 22H41c-2.77 0-5-2.48-5-5.25z" fill="#6ace7c" />
        <path d="M40.75 22L58 29.125V22z" fill="#219b38" />
        <path d="M15 42c0-1.108.892-2 2-2h24c1.108 0 2 .892 2 2v21c0 1.108-.892 2-2 2H17c-1.108 0-2-.892-2-2zm3 2h9v3h-9zm0 7h9v3h-9zm0 7h9v3h-9zm13-14h9v3h-9zm0 7h9v3h-9zm0 7h9v3h-9z" />
      </g>
    </svg>
  );
}

export default GoogleSheetsIcon;
