import { getOktaAccessToken } from '@Utils/common';
import idToken from '@Utils/iap';
import services from '@Utils/services.json';
import merge from 'lodash/merge';

function buildQueryString(params) {
  let queryString = '';
  if (typeof params === 'object') {
    if (Object.keys(params).length) {
      queryString += `?${Object.keys(params)
        .map((param) => `${param}=${params[param]}`)
        .join('&')}`;
    }
  } else {
    throw new Error('"params" must be an object');
  }
  return queryString;
}

function withTimeout(promise, timeout = 300000, abort) {
  const timeoutId = setTimeout(() => abort(), timeout);
  return promise
    .then((response) => {
      clearTimeout(timeoutId);
      return response;
    })
    .catch((err) => {
      const seconds = Math.floor(timeout / 1000) || 1;
      // checking for abort error and assigning a message
      const message =
        err.code === DOMException.ABORT_ERR
          ? `Request timed out after ${seconds} seconds`
          : err.message;
      // clearing timeout in any case
      clearTimeout(timeoutId);
      // returning rejected promise to be handled in .catch method
      return Promise.reject({ code: err.code, name: err.name, message });
    });
}

export default async function callFetch({
  abortController,
  url,
  params = {},
  init,
  timeout,
}) {
  url += buildQueryString(params);
  const oktaAccessToken = await getOktaAccessToken();
  const projectId = Object.keys(services).find((key) => url.includes(key));
  const oidcToken = await idToken(projectId);
  abortController = abortController || new AbortController();
  const response = fetch(
    url,
    merge(
      {
        headers: {
          Authorization: `Bearer ${oidcToken || oktaAccessToken}`,
        },
        signal: abortController?.signal,
      },
      init
    )
  ).catch((err) => {
    throw err;
  });
  if (timeout) {
    return withTimeout(response, timeout, abortController.abort);
  }
  return await response;
}
