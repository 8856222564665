import React from 'react';
import SvgIcon from '../SvgIcon';

function CompareArrowsIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <g>
        <rect fill="none" height="24" width="24" x="0" />
      </g>
      <g>
        <g>
          <g>
            <path d="M9.01,14H2v2h7.01v3L13,15l-3.99-4V14z M14.99,13v-3H22V8h-7.01V5L11,9L14.99,13z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default CompareArrowsIcon;
