// react
// mui
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
// npm
import classnames from 'classnames';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
// components
import Dot from '../Dot';
// styles
import useStyles from './styles';

function SidebarLink({ children, icon, label, nested, url, isSidebarOpen }) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const active = location.pathname.includes(url);

  if (!children)
    return (
      <ListItem
        button
        classes={{
          root: classnames({
            [classes.linkActive]: active && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        className={classes.link}
        component={Link}
        disableRipple
        to={url}
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: active,
          })}
        >
          {nested ? <Dot color={active && 'f5red'} /> : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: active,
              [classes.linkTextHidden]: !isSidebarOpen,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        classes={{
          root: classnames({
            [classes.linkActive]: active,
          }),
        }}
        className={classes.link}
        component={Link}
        disableRipple
        onClick={toggleCollapse}
        to={url}
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: active,
          })}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: active,
              [classes.linkTextHidden]: !isSidebarOpen,
            }),
          }}
          primary={label}
        />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {children && (
        <Collapse in={isSidebarOpen && isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <SidebarLink
                {...child}
                isSidebarOpen={isSidebarOpen}
                key={child.label}
                nested
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );

  // ###########################################################

  function toggleCollapse(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }
}

export default SidebarLink;
