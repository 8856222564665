import React from "react";

function PythonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 65 64"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <linearGradient
          id="blue"
          x1="100.00%"
          y1="38.87%"
          x2="0.00%"
          y2="38.08%"
        >
          <stop offset="0%" stopColor="#366a96" />
          <stop offset="100%" stopColor="#3679b0" />
        </linearGradient>
        <linearGradient
          id="yellow"
          x1="100.00%"
          y1="50.00%"
          x2="-97.55%"
          y2="50.00%"
        >
          <stop offset="0%" stopColor="#ffc836" />
          <stop offset="100%" stopColor="#ffe873" />
        </linearGradient>
      </defs>
      <g stroke="none" fillRule="nonzero">
        <path
          d="M16.494 8.372c0-4.307 1.181-6.65 7.704-7.766 4.428-.759 10.106-.854 15.403 0 4.183.675 7.704 3.721 7.704 7.766v14.211c0 4.168-3.418 7.582-7.704 7.582H24.198c-5.227 0-9.633 4.357-9.633 9.284v6.818H9.269c-4.48 0-7.088-3.158-8.184-7.577-1.478-5.937-1.415-9.474 0-15.159 1.227-4.96 5.148-7.577 9.628-7.577h21.189v-1.897H16.494V8.371z"
          fill="url(#blue)"
        />
        <path
          d="M47.305 53.849c0 4.307-3.824 6.489-7.704 7.577-5.838 1.64-10.523 1.389-15.403 0-4.075-1.16-7.704-3.532-7.704-7.577V39.638c0-4.09 3.487-7.582 7.704-7.582h15.403c5.13 0 9.633-4.332 9.633-9.474v-6.628h5.775c4.486 0 6.597 3.258 7.704 7.577 1.541 5.998 1.609 10.484 0 15.159-1.558 4.542-3.224 7.577-7.704 7.577H31.902v1.897h15.403v5.685z"
          fill="url(#yellow)"
        />
        <path d="M20.346 7.429c0-1.573 1.29-2.846 2.888-2.846 1.592 0 2.888 1.272 2.888 2.846 0 1.568-1.296 2.84-2.888 2.84-1.598 0-2.888-1.272-2.888-2.84zM37.68 54.791c0-1.568 1.295-2.84 2.888-2.84 1.598 0 2.888 1.272 2.888 2.84 0 1.573-1.29 2.846-2.888 2.846-1.592 0-2.888-1.272-2.888-2.846z" />
      </g>
    </svg>
  );
}
export default PythonIcon;
