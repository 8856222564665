import React from "react";

function CsvIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 51 65"
      fill="#fff"
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <defs>
        <filter id="A" x="-6%" y="-4.6875%" width="116%" height="112.5%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
          <feOffset dx="2" dy="2" result="B" />
          <feFlood floodColor="#000" floodOpacity=".2" />
          <feComposite in2="B" operator="in" result="C" />
          <feMerge>
            <feMergeNode in="C" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
        <filter id="B" x="-27.5%" y="-32.5%" width="160%" height="160%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
          <feOffset dx="1" dy="-1" result="B" />
          <feFlood floodColor="#000" floodOpacity=".2" />
          <feComposite in2="B" operator="in" result="C" />
          <feMerge>
            <feMergeNode in="C" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <path
        d="M50 20L30 0H5a4.99 4.99 0 0 0-5 5v54a4.99 4.99 0 0 0 5 5h40a4.99 4.99 0 0 0 5-5z"
        fill="#009688"
        stroke="none"
        filter="url(#A)"
      />
      <g transform="matrix(-1 0 0 -1 50 20)">
        <path
          d="M20 20L0 0h15a4.99 4.99 0 0 1 5 5z"
          fill="#4db6ac"
          stroke="none"
          filter="url(#B)"
        />
      </g>
      <path
        d="M19.153 33.349h-2.621l-1.634-2.621-1.617 2.621h-2.563l2.781-4.29-2.615-4.094h2.512l1.514 2.592 1.457-2.592h2.586l-2.655 4.273 2.856 4.112zM21.207 24h8.685v4.024h-8.685zm10.608 0H40.5v4.024h-8.685zm-10.608 5.825h8.685v4.024h-8.685zm10.608 0H40.5v4.024h-8.685zM21.207 35.65h8.685v4.024h-8.685zm10.608 0H40.5v4.024h-8.685zm-21.217 0h8.685v4.024h-8.685zm10.609 5.826h8.685V45.5h-8.685zm10.608 0H40.5V45.5h-8.685zm-21.217 0h8.685V45.5h-8.685z"
        stroke="none"
      />
    </svg>
  );
}

export default CsvIcon;
