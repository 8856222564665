import React from 'react';
import SvgIcon from '../SvgIcon';

function ArrowDropDownIcon({ ...rest }) {
  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M7 10l5 5 5-5z" />
    </SvgIcon>
  );
}

export default ArrowDropDownIcon;
