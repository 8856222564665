import React from 'react';
import SvgIcon from '../SvgIcon';

function TimeZoneIcon({ ...rest }) {
  return (
    <SvgIcon viewBox="0 0 700 700" {...rest}>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M470.133,328.246c-78.368,0-141.906,63.538-141.906,141.887c0,78.368,63.538,141.906,141.906,141.906
						c78.349,0,141.887-63.538,141.887-141.906C612.02,391.774,548.481,328.246,470.133,328.246z M479.256,575.03v-12.274
						c0-5.044-4.079-9.142-9.123-9.142c-5.064,0-9.143,4.098-9.143,9.142v12.274c-50.814-4.381-91.394-44.97-95.765-95.765H377.5
						c5.044,0,9.142-4.099,9.142-9.143c0-5.063-4.098-9.123-9.142-9.123h-12.274c4.371-50.814,44.95-91.394,95.765-95.784v12.293
						c0,5.045,4.078,9.143,9.143,9.143c5.044,0,9.123-4.098,9.123-9.143v-12.293c50.814,4.391,91.413,44.97,95.765,95.784h-12.274
						c-5.063,0-9.142,4.06-9.142,9.123c0,5.044,4.078,9.143,9.142,9.143h12.274C570.669,530.07,530.07,570.649,479.256,575.03z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  d="M523.006,456.424h-32.92c-4.371-6.352-11.66-10.527-19.953-10.527c-0.137,0-0.254,0.039-0.4,0.039l-30.003-42.219
						c-4.371-6.157-12.908-7.581-19.084-3.229c-6.157,4.391-7.621,12.947-3.23,19.104l30.013,42.257
						c-0.927,2.596-1.521,5.367-1.521,8.274c0,13.396,10.83,24.227,24.227,24.227c8.293,0,15.602-4.156,19.953-10.527h32.92
						c7.562,0,13.699-6.118,13.699-13.699C536.705,462.552,530.567,456.424,523.006,456.424z"
                />
              </g>
            </g>
          </g>
          <g>
            <g>
              <g>
                <path
                  d="M299.111,470.133c0-35.154,10.685-67.831,28.92-95.014v-44.502h43.546c27.886-19.758,61.84-31.477,98.556-31.477
						c50.678,0,96.146,22.295,127.476,57.439c3.347-17.895,5.288-36.247,5.288-55.098C602.896,135.232,467.664,0,301.521,0
						C135.252,0,0.02,135.232,0.02,301.463s135.232,301.482,301.502,301.482c18.821,0,37.193-1.941,55.088-5.288
						C321.416,566.317,299.111,520.83,299.111,470.133z M491.812,145.809c31.174,36.364,50.396,79.793,56.055,126.529h-74.212
						c-1.961-36.911-7.444-71.929-16.372-104.381C469.46,161.362,481.061,153.966,491.812,145.809z M448.931,105.961
						c-3.806,2.439-7.601,4.742-11.387,6.937c-3.347-7.317-6.811-14.343-10.43-20.968
						C434.646,96.253,441.886,100.927,448.931,105.961z M328.031,65.118c22.07,10.821,42.619,34.716,59.03,68.914
						c-18.841,5.63-38.657,9.474-59.03,11.191V65.118z M328.031,203.649c27.71-1.913,54.181-6.889,79.022-14.753
						c7.181,26.168,11.737,54.201,13.582,83.452h-92.614v-68.699H328.031z M111.084,457.156
						c-31.154-36.404-50.395-79.871-56.035-126.549h74.153c2.02,36.959,7.464,72.017,16.392,104.303
						C133.369,441.477,121.797,448.931,111.084,457.156z M129.212,272.348H55.059c5.64-46.688,24.841-90.067,55.976-126.383
						c10.528,8.059,22.129,15.455,34.579,22.158C136.676,200.409,131.154,235.398,129.212,272.348z M154.054,496.975
						c3.786-2.4,7.542-4.732,11.406-6.85c3.269,7.269,6.791,14.255,10.411,20.968C168.309,506.81,160.991,502.048,154.054,496.975z
						 M165.518,112.762c-4.098-2.234-7.874-4.479-11.464-6.801c6.947-5.035,14.255-9.708,21.817-14.031
						C172.25,98.526,168.729,105.532,165.518,112.762z M274.885,537.856c-22.129-10.85-42.619-34.794-59.088-68.982
						c18.899-5.659,38.716-9.425,59.088-11.182V537.856z M274.885,399.277c-27.632,1.961-54.103,6.908-79.022,14.801
						c-7.142-26.168-11.679-54.2-13.562-83.471h92.594v68.67H274.885z M274.885,272.348h-92.594
						c1.883-29.203,6.43-57.206,13.523-83.472c24.958,7.845,51.488,12.811,79.061,14.772v68.699H274.885z M274.885,145.272
						c-20.412-1.766-40.199-5.542-59.088-11.201c16.47-34.247,36.959-58.142,59.088-69.011V145.272z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default TimeZoneIcon;
