// react
// mui
import { Collapse, List, Typography } from '@material-ui/core';
// npm
import cx from 'classnames';
import React from 'react';
// components
import SidebarLink from '../SidebarLink';
// styles
import useStyles from './styles';

function SidebarMenu({ title, links, isSidebarOpen }) {
  const classes = useStyles();

  return (
    <List className={classes.list} component="nav">
      <Collapse in={isSidebarOpen} timeout="auto" unmountOnExit>
        {title && (
          <Typography
            className={cx(classes.title, {
              [classes.hidden]: !isSidebarOpen,
            })}
          >
            {title}
          </Typography>
        )}
      </Collapse>
      {links.map((link) => (
        <SidebarLink
          key={`${link.url}`}
          {...link}
          isSidebarOpen={isSidebarOpen}
        />
      ))}
    </List>
  );
}

export default SidebarMenu;
