import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 235;

export default makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  sidebar: {
    gridArea: "sidebar",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    paddingBottom: theme.spacing(4),
  },
  drawerClosed: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7.5),
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerToggleButton: {
    display: "flex",
    alignSelf: "flex-end",
    minHeight: "48px",
    marginTop: "48px",
    marginRight: "8px",
  },
}));
