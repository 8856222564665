import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import useStyles from './styles';

const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const SvgIcon = React.forwardRef(
  (
    {
      children,
      className,
      htmlColor,
      titleAccess,
      viewBox = '0 0 24 24',
      fontSize = 'default',
      ...other
    },
    ref
  ) => {
    const classes = useStyles();
    return (
      <svg
        className={cx(
          classes.root,
          {
            [classes[`fontSize${capitalize(fontSize)}`]]:
              fontSize !== 'default',
          },
          className
        )}
        focusable="false"
        viewBox={viewBox}
        color={htmlColor}
        aria-hidden={titleAccess ? undefined : true}
        role={titleAccess ? 'img' : undefined}
        ref={ref}
        {...other}
      >
        {children}
        {titleAccess ? <title>{titleAccess}</title> : null}
      </svg>
    );
  }
);

SvgIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  htmlColor: PropTypes.string,
  titleAccess: PropTypes.string,
  viewBox: PropTypes.string,
};

export default SvgIcon;
